import icon_1 from "../images/icons/icon_1.svg";
import icon_2 from "../images/icons/icon_2.svg";
import icon_3 from "../images/icons/icon_3.svg";

export const data = {
  title: "If you are looking for:",
  list: [
    {
      image: {
        src: icon_1,
        w: 180,
        h: 180,
        alt: "Test Automation",
      },
      text: "advance into Test Automation and earn more",
    },
    {
      image: {
        src: icon_2,
        w: 180,
        h: 180,
        alt: "Squeeze education",
      },
      text: "a way to squeeze education into a crazy schedule",
    },
    {
      image: {
        src: icon_3,
        w: 180,
        h: 180,
        alt: "Recipe",
      },
      text: "recipe you will need in order to achieve that milestone",
    },
  ],
};
