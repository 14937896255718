import classes from "../assets/styles/modules/Speakers.module.scss";
import container from "../assets/styles/modules/Container.module.scss";
import { data } from "../assets/data/speakers";
import Image from "./Image";
import section from "../assets/styles/modules/Section.module.scss";
import title from "../assets/styles/modules/Title.module.scss";
import Badge from "./Badge";

export default function Speakers() {
  return (
    <section className={section.section}>
      <div className={container.container}>
        <div className={classes.body}>
          <h2 className={title["section-title"]}>{data.title}</h2>
          <div className={classes.content}>
            <div className={classes.image}>
              <Image image={data.image} />
              <Badge />
            </div>
            <h3 className={title["speaker-name"]}>{data.name}</h3>
            <p className={classes.text}>{data.text}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
