import classes from "../assets/styles/modules/Footer.module.scss";

export default function Footer() {
  return (
    <footer className={classes.footer}>
          <a
              className={classes.button}
              href="https://bit.ly/webinarRegEngenious"
              target="_blank"
              rel="noreferrer"
            >
              REGISTER
            </a>
        </footer>
  )
}
