import image from "../images/hero-mobile.png";

export const data = {
  title: "How to start Test Automation Journey for busy Manual Testers",
  subtitle: "Free webinar",
  date: {
    day: "📅  April, 6",
    time: "🕒  05:00 PM (PST)",
  },
  text: "All registered users will receive the webinar video",
  button: {
    text: "REGISTER",
    link: "https://bit.ly/webinarRegEngenious",
  },
  image: { src: image, w: 815, h: 546, alt: "Test Automation Journey" },
};
