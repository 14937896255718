import classes from "../assets/styles/modules/Discuss.module.scss";
import container from "../assets/styles/modules/Container.module.scss";
import section from "../assets/styles/modules/Section.module.scss";
import title from "../assets/styles/modules/Title.module.scss";
import { data } from "../assets/data/discuss";
import Image from "./Image";
import { v4 as uuid } from "uuid";

export default function Discuss() {
  return (
    <section className={section.section}>
      <div className={container.container}>
        <div className={classes.body}>
          <div className={classes.content}>
            <h2 className={title["section-title-small"]}>{data.title}</h2>
            <ul className={classes.list}>
              {data.list.map((item) => (
                <li key={uuid()} className={classes.list__item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.image}>
            <Image image={data.image} />
          </div>
        </div>
      </div>
    </section>
  );
}
