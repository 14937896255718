import logo from "../images/logo-big.svg"
import image from "../images/partners.svg"

export const data = {
  title: "Organizer:",
  logo: {
    src: logo,
    w: 457,
    h: 661,
    alt: "Logo",
  },
  text:["Leading American company of the development of mobile applications in the E2E segment.", " We have 7 years of experience in developing high-quality applications for iOS / Android and Web, as well as improving existing applications.", "Among the 62 customers are Apple, Grammarly and other Fortune-500 companies"],
  image:{
    src: image,
    w: 907,
    h: 144,
    alt: "Partners",
  }
};
