import classes from "../assets/styles/modules/Header.module.scss";
import container from "../assets/styles/modules/Container.module.scss";
import logo from "../assets/images/logo-small.png"

export default function Header() {
  return (
    <header className={classes.header}>
      <div className={container.container}>
        <div className={classes.logo}>
            <img src={logo} alt="Logo" />
            </div>
      </div>
    </header>
  );
}
