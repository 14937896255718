import classes from "../assets/styles/modules/Study.module.scss";
import container from "../assets/styles/modules/Container.module.scss";
import section from "../assets/styles/modules/Section.module.scss";
import title from "../assets/styles/modules/Title.module.scss";
import { v4 as uuid } from "uuid";
import Image from "./Image";
import { data } from "../assets/data/study";

export default function Study() {
  return (
    <section className={section.section}>
      <div className={container.container}>
        <div className={classes.body}>
          <h2 className={title["section-title-small"]}>{data.title}</h2>
          <ul className={classes.list}>
            {data.list.map((item) => (
              <li className={classes.list__item} key={uuid()}>
                <div className={classes.image}>
                  <Image image={item.image} />
                </div>
                <p className={classes.text}>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
