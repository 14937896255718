import classes from "../assets/styles/modules/Organizer.module.scss";
import container from "../assets/styles/modules/Container.module.scss";
import { data } from "../assets/data/organizer";
import Image from "./Image";
import section from "../assets/styles/modules/Section.module.scss";
import title from "../assets/styles/modules/Title.module.scss";
import { v4 as uuid } from "uuid";

export default function Organizer() {
  return (
    <section className={section.section}>
      <div className={container.container}>
        <div className={classes.body}>
          <h2 className={title["section-title"]}>{data.title}</h2>
          <div className={classes.content}>
            <div className={classes.logo}>
              <Image image={data.logo} />
            </div>
            <div className={classes.text}>
              {data.text.map((item) => (
                <p key={uuid()} className={classes.text}>
                  {item}
                </p>
              ))}
            </div>
            <div className={classes.image}>
              <Image image={data.image} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
