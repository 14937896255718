import Discuss from "./components/Discuss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Organizer from "./components/Organizer";
import Speakers from "./components/Speakers";
import Study from "./components/Study";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Study />
      <Discuss />
      <Speakers />
      <Organizer />
      <Footer/>
    </div>
  );
}

export default App;
