import classes from "../assets/styles/modules/Hero.module.scss";
import container from "../assets/styles/modules/Container.module.scss";
import { data } from "../assets/data/hero";
import Image from "./Image";
import section from "../assets/styles/modules/Section.module.scss";

export default function Hero() {
  return (
    <section className={section.section}>
      <div className={container.container}>
        <div className={classes.body}>
          <div className={classes.content}>
            <h3 className={classes.subtitle}>{data.subtitle}</h3>
            <div className={classes.date}>
              <p>{data.date.day}</p>
              <p>{data.date.time}</p>
            </div>
            <h1 className={classes.title}>{data.title}</h1>
            <div className={classes.image}>
              <Image image={data.image} />
            </div>
            <p className={classes.text}>{data.text}</p>
            <a
              className={classes.button}
              href={data.button.link}
              target="_blank"
              rel="noreferrer"
            >
              {data.button.text}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
