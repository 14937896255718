import image from "../images/section.png";

export const data = {
  title: "Topics we will discuss:",
  list: [
    "What programming language to learn?",
    "How to choose your expertise? (iOS vs Android, Web or Backend)",
    "How to break the ice? When to start coding?",
    "First project",
    "Salary expectation",
    "Resume and job search for Automation Engineers",
  ],
  image: {
    src: image,
    w: 518,
    h: 432,
    alt: "Topics",
  },
};
